import * as React from "react";
import parse from 'html-react-parser';
import "./doctors.css";
import Layout from "../layouts/layout";
import { FaFileInvoice } from 'react-icons/fa';
import { Link } from "gatsby";
import favicon from "../images/favico.ico";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image"


const ChirurgiensPage = (props) => {
    const { content } = props.pageContext;
    const { title } = props.pageContext;
    const { titleMeta } = props.pageContext;
    const { metaDesc } = props.pageContext;
    const { childs } = props.pageContext;
    const { uri } = props.pageContext;
    const path = typeof window !== "undefined" 
    ? window.location.pathname.split("/").filter(Boolean).pop() 
    : "";
      console.log("paath",path); 
    return (
        <>
            <Layout>
                <Helmet htmlAttributes={{
                    lang: 'fr-FR',
                }}>
                    <link rel="icon" type="image/x-icon" href={favicon} />
                    <title>{titleMeta}</title>
                    <meta name="description" content={metaDesc} />
                    <meta name="robots" content="index, follow" />
                    <link data-react-helmet="true" rel="canonical" href={"https://www.medica-tour.fr" + uri}></link>
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-TSKFDDEV9E"></script>
                    <script>
      {`
        window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-TSKFDDEV9E');
      `}
    </script>
    <script>
      {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-MC7KJQM');`}
    </script>
                     <script>
                        {`
                            !function(f,b,e,v,n,t,s)
                            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                            n.queue=[];t=b.createElement(e);t.async=!0;
                            t.src=v;s=b.getElementsByTagName(e)[0];
                            s.parentNode.insertBefore(t,s)}(window, document,'script',
                            'https://connect.facebook.net/en_US/fbevents.js');
                            fbq('init', '8919602118131306');
                            fbq('track', 'PageView');
                        `}
                    </script>
</Helmet>    
    <noscript>
                    <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MC7KJQM"
                        height="0" width="0" style={{ display: "none", visibility: "hidden" }}></iframe>
                    <img height="1" width="1" style={{ display: "none" }}
                        src="https://www.facebook.com/tr?id=8919602118131306&ev=PageView&noscript=1" />
                </noscript>
            <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MC7KJQM"
height="0" width="0" style={{display:"none",visibility:"hidden"}} ></iframe></noscript>
        {path === "chirurgiens-esthetiques-tunisie" && (
    <div className="image-container">
        <StaticImage 
            src="../images/nos chirurgiens.webp" 
            className="img-fluid service-image" 
            alt="Nos chirurgiens hautement qualifiés en Tunisie - Experts en chirurgie esthétique et soins médicaux spécialisés." 
        />
        <h1 className="service-title">{title}</h1>
    </div>
)}

                <div className="container-fluid cont3-style pb-5 pt-5">
                    <div className="container ">
                        <div className="row">

                            {childs && childs.map((child, i) => {
                                return (
                                    <div className="col-sm-12 col-md-4 pt-2">
                                        <div className="card h-100" >
                                            <Link to={child.uri} className="link-cv">
                                                <img src={child.featuredImage.sourceUrl} className="card-img-top img-fluid" alt={child.title} />
                                            </Link>
                                            <div className="card-body card-body-intervention   w-100">
                                                <span className="spec-doc">{child.Specialite.specialiste}</span><br />
                                                <Link to={child.uri} className="link-interv">
                                                    <span className="mb-2 card-body-title">{child.title}</span>
                                                    <span className="float-end">
                                                        <Link to={child.uri} className="link-cv">
                                                            <FaFileInvoice />
                                                        </Link>
                                                    </span>
                                                </Link>

                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                </div>

                <div className="container-fluid  pb-5 pt-2">
                    <div className="container ">
                        <div className="row">
                            {content && parse(content)}
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default ChirurgiensPage
